import styled from 'styled-components';
import {AnchorLink} from '../../../components/Common/common.style';
import {device} from '../../../components/Common/device';
import {Search} from '@styled-icons/heroicons-outline/Search';

export const BlogSidebarWrapper = styled.div`
    background:#fff;
    box-shadow: 0px 0px 8px 1px #ddd;
    border-radius: 4px;
    padding:20px;

    @media ${device.mobileL} {
        padding:15px;
    }
`;

export const RecentPostLayout = styled.div`
    display:flex;
    align-items:center;
    margin-bottom: 15px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 15px;

    :last-child{
        border:none;
        margin-bottom:0px;
        padding-bottom:0px;
    }
`;
export const PostImageHolder = styled.div`
    width:80px;
    flex-shrink:0;

    @media ${device.laptop} {
        width:80px;
    }

    img{
        width: 80px;
        object-fit: cover;
        height: 80px;
        border-radius: 4px;
        flex-shrink: 0;
    }
`;
export const PostHeading = styled(AnchorLink)`
    margin-left:10px;
    margin-bottom:0px;

    h6{
        margin-bottom:0px;
    }
`;

export const Divider = styled.hr`
    margin:20px 0px;
`;
export const BlogSearchbarWrapper = styled.div`
    background: #fff;
    box-shadow: 0px 0px 8px 1px #ddd;
    margin-bottom: 30px;
    border-radius: 4px;
    padding: 20px;

    h5{
        margin-bottom:10px;
    }
`;

export const BlogSearchInput = styled.div`
    position: relative;

    input {
        border: 1px solid #ddd;
        border-radius: 4px;
        padding: 10px;
        background: #f5f5f5;
        width: 100%;
        position: relative;
    }

    svg {
        position: absolute;
        top: 12px;
        height: 20px;
        right: 15px;
    }
`;

export const SearchIcon = styled(Search)`

`;
