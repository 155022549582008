import React from 'react';
import {BlogListLayout,BlogListWrapper,BlogImageHolder,BlogdetailsAdmin,BlogDetailsText,
    BlogDetailsTextLeft,BlogDetailsTextRight,PaginationWrapper,LeftIcon,RightIcon
} from './bloglist.style';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useStaticQuery, graphql } from 'gatsby';
import GatsImg from "gatsby-image";

const BlogListPage  = () => {

    const JSONData  = useStaticQuery(graphql`
    query {
        blogPage1Json{
            BlogList{
                BlogImage{
                    childImageSharp{
                        fluid(quality: 100){
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
                Title
                UserImage
                UserText
                CommentImage
                CommentText
                BlogDate
                BlogMonth
                BlogText
                ReadMore
            }
        }
    }
  `);
  const BlogData = JSONData.blogPage1Json;

    return (
        <BlogListWrapper>
        {
            BlogData.BlogList.map((item,idx) => {
                return <BlogListLayout>

                    <BlogImageHolder>
                        <GatsImg
                            fluid={item.BlogImage.childImageSharp.fluid}
                            alt=""
                        />
                    </BlogImageHolder>

                    <BlogDetailsText>
                        <BlogDetailsTextLeft>
                            <h3>
                                {item.BlogDate}
                            </h3>
                            <h5>
                                {item.BlogMonth}
                            </h5>
                        </BlogDetailsTextLeft>
                        <BlogDetailsTextRight>
                            <a href="/">
                                <h4>
                                    {item.Title}
                                </h4>
                            </a>
                            <BlogdetailsAdmin>
                                <p>
                                    <img alt="" src={item.UserImage}  />
                                    <span>{item.UserText}</span>
                                </p>
                                <p>
                                    <img alt="" src={item.CommentImage} />
                                    <span>{item.CommentText}</span>
                                </p>
                            </BlogdetailsAdmin>

                            <p>{item.BlogText}</p>

                        </BlogDetailsTextRight>
                    </BlogDetailsText>
                </BlogListLayout>
            })
            }

            <PaginationWrapper>
                <ul>
                    <li>
                        <a href="/"><LeftIcon/></a>
                    </li>
                    <li>
                        <a class="active" href="/">1</a>
                    </li>
                    <li>
                        <a href="/">2</a>
                    </li>
                    <li>
                        <a href="/">3</a>
                    </li>
                    <li>
                        <a href="/">4</a>
                    </li>
                    <li>
                        <a href="/">&nbsp;<RightIcon/></a>
                    </li>
                </ul>
            </PaginationWrapper>
        </BlogListWrapper>
    );
}
export default BlogListPage;
