import styled from 'styled-components';
import {device} from '../../../components/Common/device';
import {ChevronsLeft} from '@styled-icons/boxicons-regular/ChevronsLeft'
import {ChevronsRight} from '@styled-icons/boxicons-regular/ChevronsRight'

export const BlogListLayout = styled.div`
    margin-bottom:40px;

    :last-child{
        margin-bottom:0px;
    }

    @media ${device.laptop} {

    }

    @media ${device.mobileM} {

    }
`;
export const BlogListWrapper = styled.div`

`;
export const BlogImageHolder = styled.div`
    margin-bottom:20px;
    object-fit: cover;
    height: 380px;
    min-height: 100%;
    border-radius: 5px;
    overflow: hidden;

    @media ${device.laptopM} {
        height: auto;
    }
`;


export const BlogdetailsAdmin = styled.div`
    margin-bottom: 10px;

    p {
        margin-right: 15px;
        display: inline-block;
        color: #545454;
        margin-bottom: 0px;
    }
    img {
       height: 18px;
       width: auto;
       margin-bottom: 0px;
    }
    span {
        font-size: 14px;
        margin-left:5px;
    }
`;

export const BlogDetailsText = styled.div`
    display: flex;

    @media ${device.tablet} {
        flex-direction:column;
        position:relative;
    }
`;

export const BlogDetailsTextLeft = styled.div`
    min-width: 100px;
    flex-shrink: 0;
    background: #ff3c1c;
    height: fit-content;
    text-align: center;
    padding: 10px;

    h3 {
        text-align: center;
        margin-bottom: 0px;
        color: #fff;
        line-height: 1;
    }
    h5 {
        text-align: center;
        margin-bottom: 0px;
        color: #fff;
        line-height: 1;
    }

    @media ${device.tablet} {
        position: absolute;
        top: -96px;
        border-bottom-left-radius: 4px;
    }

    @media ${device.mobileL} {
        position: absolute;
        top: -92px;
        border-bottom-left-radius: 4px;
    }

`;

export const BlogDetailsTextRight = styled.div`
    margin-left: 15px;

    h4 {
        margin-bottom: 0px;
    }
    a:hover{
        text-decoration:none;
    }
    a:hover h4{
        color: #ff3c1c;
    }

    p{
        margin-bottom:0;
    }

    @media ${device.tablet} {
        margin-left:0px;
    }

    @media ${device.mobileL} {
        margin-left:0px;
    }

`;
export const PaginationWrapper = styled.div`
    margin-top: 20px;

    @media ${device.laptop} {
        margin-bottom:40px;
    }

    ul{
        padding:0px;
        margin:0px;
    }
    ul li{
        list-style: none;
        display: inline-block;
        margin-bottom: 0px;
    }
    ul li a {
        padding: 10px 20px;
        border-radius: 4px;
        margin-left: 5px;
        color: #ff3c1c;
        text-decoration:none;
        display: inline-block;
        margin-bottom: 10px;
        background: #fff;
        font-size:16px;
        box-shadow: 0px 0px 10px 0px #d0d0d0;
    }
    ul li a.active {
    color: #fff;
    background: #ff3c1c;
    }
    ul li a:hover {
    color: #fff;
    background: #ff3c1c;
    }
`;
export const LeftIcon = styled(ChevronsLeft)`
    height:20px;
`;
export const RightIcon = styled(ChevronsRight)`
    height:20px;
`;
