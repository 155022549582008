import React from "react";
import Banner from "../components/CommonBanner";
import HeaderMenuBS from "../components/HeaderMenuBS"
import Footer from "../components/Footer";
import BlogPage from "../containers/BlogPage1/BlogPage";
import Layout from "../components/layout";
import SEO from "../components/seo";
import BackgroundImg from '../assets/blog-1-images/banner.jpg';

const BlogListPage = () => (
    <Layout>
        <SEO
            title="Beryl - Business, Corporate Gatsbyjs Website | Gatsbyjs Website Themes"
            description="Buy Responsive Business and Corporate Themes built with GatsbyJS, React Bootstrap and Styled-Components"
        />
        <HeaderMenuBS />
        <Banner 
            BackgroundImg={BackgroundImg}
            pageTitle="Our Blogs"
            breadCrumbsFirst="Home"
            breadCrumbsSecond="Our Blogs"
            breadCrumbsFirstLink="/"
            breadCrumbsSecondLink="/blog-list"
        />
        <BlogPage />
        <Footer />
    </Layout>
)

export default BlogListPage;
