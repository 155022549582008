import React from 'react';
import {BlogSidebarWrapper,RecentPostLayout,
    PostImageHolder,PostHeading,Divider,BlogSearchbarWrapper,BlogSearchInput,SearchIcon
} from './blogsidebar.style';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useStaticQuery, graphql } from 'gatsby';
import GatsImg from "gatsby-image";

const BlogSidebarPage  = () => {

    const JSONData  = useStaticQuery(graphql`
    query {
        blogPage1Json{
            BlogSidebar{
                RecentPostHeading
                RecentPost{
                    PostImage{
                        childImageSharp{
                            fluid(quality: 100){
                            ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    PostHeading
                }
            }
        }
    }
  `);

  const BlogData = JSONData.blogPage1Json.BlogSidebar;

    return (
        <div>
        <BlogSearchbarWrapper>
            <h5>Search Blog</h5>
            <BlogSearchInput>
                <input type="Search" placeholder="Search"/><SearchIcon/>
            </BlogSearchInput>
        </BlogSearchbarWrapper>

        <BlogSidebarWrapper>
            <h5>
                {BlogData.RecentPostHeading}
            </h5>
            {
                BlogData.RecentPost.map((item,idx) => {
                    return <RecentPostLayout>
                        <PostImageHolder>
                            <GatsImg
                                fluid={item.PostImage.childImageSharp.fluid}
                                alt=""
                                imgStyle={{
                                    width:"100%",
                                    height:"auto"
                                }}
                            />
                        </PostImageHolder>
                        <PostHeading href="">
                            <h6>{item.PostHeading}</h6>
                        </PostHeading>
                        <Divider />
                    </RecentPostLayout>
                })
            }
        </BlogSidebarWrapper>
        </div>
    );
}
export default BlogSidebarPage;
