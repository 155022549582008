import styled from 'styled-components';
import {device} from '../../../components/Common/device';

export const BlogSection = styled.section`
    padding:100px 0px 90px;

    @media ${device.laptop} {
        padding:100px 5px;
    }
    @media ${device.tablet} {
        padding:80px 5px;
    }
`;
